export const useLeakprofileAccountStore = defineStore('leakprofileAccount', {
  actions: {
    async completeAccount(leakprofileId: string, account: LeakProfileAccount) {
      try {
        account.completed = !account.completed;
        const res = await $fetch(`/api/v1/leakprofiles/${leakprofileId}/accounts/${account.id}/complete/`, {
          method: 'POST',
          body: { 
            completed: account.completed 
          },
        });
        Object.assign(account, res);
        return account;
      } catch (error) {
        account.completed = !account.completed;
        throw error;
      }
    },
    async completeAllAccounts(options: { leakprofileId: string, query?: any, completed: boolean }) {
      const queryString = new URLSearchParams(options.query).toString();
      const res = await $fetch(`/api/v1/leakprofiles/${options.leakprofileId}/accounts/complete-all/?${queryString}`, {
        method: 'POST',
        body: { 
          completed: options.completed,
        },
      });
      return res;
    },
    async completeAlert(options: { leakprofileId: string, alertId: string, completed: boolean }) {
      return await $fetch<Alert>(`/api/v1/leakprofiles/${options.leakprofileId}/alerts-meta/${options.alertId}/complete-all/`, {
        method: 'POST',
        body: { 
          completed: options.completed 
        },
      });
    },
  }
});
